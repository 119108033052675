import { FormattedMessage, useIntl } from 'react-intl'

import { usePrinting, useReport } from './Context'
import * as Page from 'components/page'
import { DetailSet } from './DetailSet'
import { Confidence } from './Confidence'
import OffSeason from './OffSeason'
import { TravelAndTerrainAdvice } from './TravelAndTerrainAdvice'
import ProblemSet from './problem'
import { DangerRatings } from './danger'
import { Summary } from './Summary'
import Shim from 'components/Shim'

import styles from './Forecast.module.css'

export const TabSet = () => {
    const intl = useIntl()
    const dangerRatingsHeader = intl.formatMessage({
        defaultMessage: 'Danger ratings',
        description: 'FX tab header',
    })
    const problemsHeader = intl.formatMessage({
        defaultMessage: 'Problems',
        description: 'FX tab header',
    })
    const detailsHeader = intl.formatMessage({
        defaultMessage: 'Details',
        description: 'FX tab header',
    })

    const { confidence } = useReport()
    const { rating } = confidence

    return (
        <>
            <Summary
                title={intl.formatMessage({
                    defaultMessage: 'Danger Ratings',
                    description: 'forecast header',
                })}>
                <DangerRatings />
            </Summary>
            <Summary
                title={intl.formatMessage({
                    defaultMessage: 'Terrain and Travel Advice',
                    description: 'forecast header',
                })}>
                <TravelAndTerrainAdvice />
            </Summary>
            <Summary
                title={intl.formatMessage({
                    defaultMessage: 'Problems',
                    description: 'forecast header',
                })}>
                <ProblemSet />
            </Summary>
            <DetailSet />
            {confidence && (
                <Summary
                    title={intl.formatMessage({
                        defaultMessage: 'Confidence',
                        description: 'forecast header',
                    })}>
                    <h4 style={style.confidence}>{rating?.display}</h4>
                    <Confidence />
                </Summary>
            )}
        </>
    )
}

const style = {
    confidence: {
        marginTop: '0',
        marginBottom: '0.5em',
    },
}
